import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './login/app.login.component';
import {AuthGuard} from './auth/auth.guard';
import {JobsManagerComponent} from './jobs/jobs-manager/jobs-manager.component';
import {FormsManagerComponent} from './forms/forms-manager/forms-manager.component';
import {InspectionComponent} from './inspection/inspection.component';
import {PhotoLabelsComponent} from './settings/photo-labels/photo-labels.component';
import { CanDeactivateGuard } from './can-deactivate-guard.service';
import { UserComponent } from './users/users.component';
import { InspectionGuestComponent } from './inspection-guest/inspection-guest.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '', component: AppMainComponent,
        canActivate: [AuthGuard],
        children: [
          {path: '', component: JobsManagerComponent},
          {path: 'forms-manager', component: FormsManagerComponent},
          {
            path: 'settings', children: [
              {path: 'photo-labels', component: PhotoLabelsComponent}
            ]
          },
          {path: 'users', component: UserComponent},
        ]
      },
      {path: 'inspection/:jobId/:token', component: InspectionGuestComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'inspection-form/:jobId/:token', component: InspectionGuestComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'error', component: AppErrorComponent},
      {path: 'access', component: AppAccessdeniedComponent},
      {path: 'notfound', component: AppNotfoundComponent},
      {path: 'login', component: AppLoginComponent},
      {path: 'password-reset', component: ResetPasswordComponent, pathMatch: 'full'},
      {path: '**', redirectTo: '/notfound'},
    ], {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule],
  providers: [CanDeactivateGuard]
})
export class AppRoutingModule {
}


