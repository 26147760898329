import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  async passwordReset(email: string): Promise<void> {
    try {
      await firstValueFrom( this.http.post(`/api/auth/request-password-reset`, { email }) );
    } catch (e) { 
      console.error(`Unable to submit password reset request.\n${e}`) 
    }
  }
}

