<div class="p-grid p-align-center p-justify-center">
  <div class="p-col-12 p-md-6">
    <p-card header="Reset Your Password">
      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <div class="p-fluid">
          <div class="p-field">
            <label for="newPassword">New Password</label>
            <p-password
              id="newPassword"
              formControlName="newPassword"
              [feedback]="true"
              toggleMask="true"
              promptLabel="Enter a new password"
              weakLabel="Weak"
              mediumLabel="Medium"
              strongLabel="Strong"
              required
            ></p-password>
            <div *ngIf="resetPasswordForm.controls['newPassword'].invalid && resetPasswordForm.controls['newPassword'].touched">
              <small class="p-error">Password must be at least 6 characters long.</small>
            </div>
          </div>

          <div class="p-field">
            <label for="confirmPassword">Confirm Password</label>
            <p-password
              id="confirmPassword"
              formControlName="confirmPassword"
              [feedback]="false"
              toggleMask="true"
              required
            ></p-password>
            <div *ngIf="resetPasswordForm.controls['confirmPassword'].invalid && resetPasswordForm.controls['confirmPassword'].touched">
              <small class="p-error">Passwords must match.</small>
            </div>
          </div>

          <div class="p-field">
            <p-button label="Reset Password" type="submit" [disabled]="resetPasswordForm.invalid"></p-button>
          </div>
        </div>
      </form>
    </p-card>
  </div>
</div>
