<!-- <div class="login-body">
    <div class="login-panel p-fluid">
        <div class="login-panel-header">
            <img src="assets/layout/images/login/logo-sapphire-color.png">
        </div>
      <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
        <p-toast position="top-center"></p-toast>
        <div class="login-panel-content">
            <div class="p-grid">
                <div class="p-col-12">
                    <h1>CSI SITECHECK INSPECTION</h1>
                    <h2>Welcome, please use the form to sign in</h2>
                </div>
                <div class="p-col-12">
                    <span class="p-float-label">
                        <input id="username" type="text" pInputText ngModel name="username">
                        <label for="username">Username</label>
                    </span>
                </div>
                <div class="p-col-12">
                    <span class="p-float-label">
                        <input id="password" type="password" pInputText ngModel name="password">
                        <label for="password">Password</label>
                    </span>
                </div>
                <div class="p-col-6" style="padding-top: 1.25em">
                   <a href="#">Forget Password?</a>
                  <p>Forgot Password?</p>
                </div>
                <div class="p-col-6" style="text-align: right">
                    <button pButton pRipple type="submit" [disabled]="!loginForm.valid" label="NEXT" style="width: 100%"></button>
                </div>
            </div>
        </div>
      </form>
    </div>
</div> -->
<p-toast key="loginToast" position="top-center"></p-toast>
<p-dialog header="Edit Profile" [modal]="true" [(visible)]="passwordResetVisible" [style]="{ width: '25rem' }">
    <span class="p-text-secondary block mb-5">Enter the email address associated with your login. If your email matches a valid login, you will receive an email with a password reset link.</span>
    <div class="flex align-items-center gap-3 mb-5">
        <label for="email" class="font-semibold w-6rem">Email</label>
        <input #email pInputText id="email" class="flex-auto" autocomplete="off" />
    </div>
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" severity="secondary" (onClick)="passwordResetVisible = false" />
        <p-button label="Continue" (onClick)="submitResetPassword(email)" />
    </div>
</p-dialog>
<div class="login-body">
    <div class="login-panel p-fluid">
        <div class="login-panel-header">
            <img class="logo-image" src="assets/layout/images/login/sitecheck-logo.jpg">
        </div>
        <div class="login-panel-content">
            <div class="grid">
                <div class="col-12">
                    <h1>Inspection Management System</h1>
                    <h2>Welcome, please use the form to sign-in</h2>
                    <!-- <span class="text-center"><small><i>For official use only by authorized users</i></small></span> -->
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <input id="username" type="text" [(ngModel)]="username" pInputText>
                        <label for="username">Username</label>
                    </span>
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <input id="password" type="password" [(ngModel)]="password" pInputText>
                        <label for="password">Password</label>
                    </span>
                </div>
                <div class="col-6" style="padding-top: 1.25em">
                    <a href="#" (click)="passwordResetVisible=true">Forgot Password?</a>
                </div>
                <div class="col-6" style="text-align: right">
                    <button pButton pRipple type="button" label="NEXT" style="width: 100%" (click)="login()"></button>
                </div>
            </div>
        </div>
    </div>
</div>

