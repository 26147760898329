import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { AuthService, AuthLoginResponseData } from '../auth/auth.service';
import { LoginService } from './app.login.service';
@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['./app.login.component.scss']
})
export class AppLoginComponent {

  username = '';
  password = '';

  passwordResetVisible = false;
  constructor(private authService: AuthService, private router: Router, private messageService: MessageService, private loginService: LoginService) {}

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    const loginName = form.value.username;
    const password = form.value.password;

    let authObs: Observable<AuthLoginResponseData>;

    authObs = this.authService.login(loginName, password);

    authObs.subscribe(
      resData => {
        this.router.navigate(['/']);
      },
      errorMessage => {
        this.messageService.add(
          { 
            severity: 'error',
            summary: 'Unable to login',
            detail: errorMessage,
            key: 'loginToast' 
          });
      }
    );

    form.reset();
  }

  login() {
    let authObs: Observable<AuthLoginResponseData>;

    authObs = this.authService.login(this.username, this.password);

    authObs.subscribe(
      resData => {
        this.router.navigate(['/']);
      },
      errorMessage => {
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Unable to login',
            detail: errorMessage,
            key: 'loginToast'
          });
      }
    );

  }

  async submitResetPassword(emailElement: any): Promise<void> {
    await this.loginService.passwordReset(emailElement.value);
    this.passwordResetVisible = false;
  }


}
